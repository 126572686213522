import React from 'react'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { SuccessCases } from '../../../components/SuccessCases'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import banner1 from '../../../images/pages/controladoria/gestao-kpi-1.jpeg'
import banner2 from '../../../images/pages/controladoria/gestao-kpi-2.jpeg'
import {
  faExchangeAlt,
  faUsers,
  faCalendarAlt,
  faChartPie,
} from '@fortawesome/free-solid-svg-icons'

const slides = [
  {
    title: `GESTÃO POR INDICADORES ESTRATÉGICOS KPIs`,
    description: (
      <>
        Melhore os resultados do seu negócio com definições claras de metas e
        por meio de planos de ação consistentes.
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
  {
    title: `GESTÃO POR INDICADORES ESTRATÉGICOS KPIs`,
    description: (
      <>
        Alcance os resultados pretendidos através da Gestão Compartilhada e um
        melhor Processo Decisório.
      </>
    ),
    backgroundUrl: banner2,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const cases = [
  {
    title: `Diman Serviços Mecânicos`,
    text: `Implantação da rotina de gestão através da reunião mensal de análise
    de resultados. Realizadas mudanças na gestão através da classificação
    adequada de gastos, sistematização de relatórios e planejamento
    orçamentário.`,
  },
  {
    title: `TPC Law`,
    text: `Implantação da rotina de gestão para controles operacionais e
    tomada de decisões pautada em KPIs, possibilitando um crescimento
    seguro e sustentável.`,
  },
  {
    title: `Tecca Engenharia de Sistemas`,
    text: `Após o projeto foram fechados dois grandes negócios, resultado de uma
    maior assertividade na formação do preço de venda. Trabalho feito
    com foco nos custos diretos e créditos e débitos de impostos.`,
  },
]

const GestaoKPI: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faChartPie,
      title: `Criação dos Indicadores (KPIs)`,
      description: (
        <>
          Nesta etapa, elaboramos um relatório histórico com os principais
          indicadores que direcionam o resultado do negócio, nos níveis:
          estratégico, tático e operacional.
        </>
      ),
    },
    {
      faIcon: faUsers,
      title: `Plano de Metas`,
      description: (
        <>
          Iremos estabelecer um Plano de Metas com Meritocracias ligadas aos
          donos dos KPIs. Este plano motiva e atrai responsabilidades para a
          equipe. É boa prática de mercado a divisão de parte do ganho
          excedente.
        </>
      ),
    },
    {
      faIcon: faCalendarAlt,
      title: `Relatórios Mensais`,
      description: (
        <>
          Iremos analisar através dos relatórios mensais o resultado previsto
          vs. realizado dos KPIs para mensurar o desempenho do negócio. Esta
          etapa acontece durante a reunião mensal de resultados.
        </>
      ),
    },
    {
      faIcon: faExchangeAlt,
      title: `Plano de Açāo`,
      description: (
        <>
          Baseados na ferramenta 5W2H, são gerados nas reuniões mensais de
          resultado e servem para melhorar os KPIs que estiverem abaixo das
          metas estabelecidas. Nesta etapa acontece a Mudança.
        </>
      ),
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Diagnóstico',
      description: (
        <>
          Durante a fase de diagnóstico, iremos gerar um relatório histórico com
          os principais indicadores que direcionam o resultado do negócio.
          Entendemos a relação entre os KPIs, construindo uma{' '}
          <a
            href="https://blog.valoremelhoria.com.br/2021-05-20-como-os-kpis-podem-melhorar-o-resultado-do-seu-neg%C3%B3cio/#indicadores"
            target="_blank"
            rel="noopener"
          >
            árvore de indicadores{' '}
          </a>
          desde o nível estratégico e tático ao operacional.{' '}
          <a
            href="https://blog.valoremelhoria.com.br/2021-06-28-boas-pr%C3%A1ticas-para-gest%C3%A3o-por-indicadores/"
            target="_blank"
            rel="noopener"
          >
            Assista ao nosso vídeo sobre boas práticas para gestão por
            indicadores.
          </a>
        </>
      ),
    },
    {
      count: 2,
      title: 'Plano de Metas',
      description: (
        <>
          Estabeleceremos um plano de metas SMART, com metas específicas,
          mensuráveis, atingíveis, relevantes e temporais. O plano de metas é
          elaborado com Meritocracias ligadas aos donos dos KPIs. É importante
          deixar clara a recompensa ao time com base no cumprimento destas
          metas.
        </>
      ),
    },
    {
      count: 3,
      title: 'Report Mensal',
      description: (
        <>
          Fase em que ocorre o Processo Decisório embasado no relatório mensal
          comparando-se o resultado previsto vs realizado dos indicadores para
          mensurar o desempenho do negócio. Os donos dos KPIs serão treinados
          para realizare a análise, compilação e apresentação dos resultados.
        </>
      ),
    },
    {
      count: 4,
      title: 'Gestão da Mudança (GMO)',
      description: (
        <>
          Como acontece a Gestão da Mudança Organizacional? Por meio dos planos
          de ações gerados nas reuniões mensais de resultado, que servem para
          melhorar os KPIs que estiverem abaixo das metas. Utilizamos técnicas
          de GMO dos instituto HCMI para engajar e "puxar" as mudanças para que
          não fiquem apenas no papel.{' '}
          <a
            href="https://blog.valoremelhoria.com.br/2021-08-24-boas-pr%C3%A1ticas-para-a-gest%C3%A3o-da-mudan%C3%A7a/"
            target="_blank"
            rel="noopener"
          >
            Assista ao nosso vídeo sobre boas práticas para a gestão da mudança.
          </a>
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="Gestāo por indicadores e KPIS - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="
              Propor soluções através do uso da Controladoria para melhorar o resultado do seu negócio, fazendo uma Gestão Compartilhada e melhorando o Processo Decisório, ligado à Meritocracia e Planos de Ação consistentes."
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
          />
        </div>
      </section>

      <SuccessCases listCases={cases} isNumericCases={true} />
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default GestaoKPI
